@import '../../scss/vars';

.plans_container {
    margin-top: 4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;

    .plans-blur1 {
       width: 32rem;
       height: 23rem;
       left: 0;
       top: 6rem;
    }

    .plans-blur2 {
        width: 32rem;
        height: 23rem;
        right: 0;
        top: 10rem;
     }

    .plans_header {
        display: flex;
        justify-content: center;
        gap: 2rem;
        font-style: italic;
        span {
            font-size: 3rem;
            font-weight: bold;
        }

        :nth-child(2){
            color: white;
        }
    }

    .plans {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;





        .plan {
            display: flex;
            flex-direction: column;
            background: $caloryCard;
            color: white;
            gap: 2rem;
            padding: 1.5rem;
            width: 15rem;

            button {
                padding: .5rem;
                border: 4px solid transparent;
                font-weight: bold;
            }
            
            &:nth-child(2) {
                svg {
                    fill: white;
                }
                button {
                    color: orange;
                
                }
            }

            &:nth-child(2) {
                background: $planCard;
                transform: scale(1.1);
            }

            :nth-child(2) {
                font-size: 1rem;
                font-weight: bold;
            }

            :nth-child(3) {
                font-size: 3rem;
                font-weight: bold;
            }

            :nth-child(5) {
                font-size: 0.9rem;
                font-weight: bold;
            }

            svg {
                fill: $orange;
                width: 2rem;
                height: 2rem;      
            }



            .features {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .feature {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    img {
                        width: 1rem;  
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 786px) {
    .plans_container {
        .plans {
            transform: scale(0.8);
            flex-direction: column;
        }
        .plans_header {
            flex-direction: column;
            text-align: center;
        }
    }

}