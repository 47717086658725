@import '../../scss/vars';


.hero {
    display: flex;
    justify-content: space-between;

    .hero-blur {
        width: 22rem;
        height: 30rem;
        left: 0;
    }

    .left_h {
        padding: 2rem;
        padding-top: 1.5rem;
        flex: 3;

        display: flex;
        gap: 2rem;
        flex-direction: column;

        .the_best_ad {
            margin-top: 4rem;
            border-radius: 4rem;
            width: fit-content;
            padding: 20px 13px;

            color: white;
            background-color: #363d42;
            text-transform: uppercase;
            
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            span {
                z-index: 2;
            }

            div {
                position: absolute;
                background-color: $orange;
                width: 5.4rem;
                height: 80%;
                left: 8px;
                border-radius: 3rem;
                z-index: 1;
            }
        }

        .hero_text {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            text-transform: uppercase;
            font-size: 4.5rem;
            font-weight: bold;
            color: white;
            text-overflow: inherit;

            .hero_text_span {
                font-size: 1rem;
                font-weight: 200;
                text-transform: none;
                width: 80%;
                letter-spacing: 1px;
            }
        }

        .figures {
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-self: center;
            div {
                display: flex;
                flex-direction: column;
                text-align: center;
                span:nth-of-type(1) {
                    color: white;
                    font-size: 2rem;
                }

                span:nth-of-type(2) {
                    color: $gray;
                    text-transform: uppercase;
                }
            }
        }

        .hero_buttons {

            display: flex;
            gap: 1rem;
            font-weight: normal;


             button {
                background-color: white;
                padding: 0.5rem;
                font-weight: bold;
                border: 4px solid transparent;
                transition: 300ms all;
                
                &:hover {
                    cursor: pointer;
                }

                &:nth-child(1) {
                    color: white;
                    background-color: $orange;
                    width: 8rem;
                }

                &:nth-child(2) {
                    color: white;
                    background-color: transparent;
                    width: 8rem;
                    border: 2px solid $orange;
                }
             }
        }

    }
    .right_h {
        flex: 1;
        position: relative;
        background-color: $orange;

        button {
            position: absolute;
            right: 3rem;
            top: 2rem;

            color: black;
            background-color: white;
            font-weight: 600;

            border: 4px solid transparent;
            padding: 0.5rem; 
        }
        .heart_rate {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            background-color: $darkGrey;
            width: fit-content;
            padding: 1rem;
            align-items: start;
            border-radius: 5px;
            position: absolute;
            right: 4rem;
            top: 7rem;

            img {
                width: 2rem;
            }

            span {
                color: $gray;
            }
            
            .heart_rate_span{
                color: white;
                font-size: 1.5rem;
            }
        }

        .hero_image {
            position: absolute;
            top: 10rem;
            right: 8rem;
            width: 23rem;
        }

        .hero_image_back {
            position: absolute;
            top: 4rem;
            right: 20rem;
            z-index: -1;
            width: 15rem;
        }

        .calories {
            display: flex;
            gap: 2rem;
            background-color: $caloryCard;
            border-radius: 5px;
            padding: 1rem;
            width: fit-content;

            position: absolute;
            top: 32rem;
            right: 28rem;

            img {
                width: 3rem;
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                :nth-of-type(1) {
                    color: $gray;
                }
                :nth-of-type(2) {
                    color: white;
                    font-size: 1.5rem;
                }
            }
        }
    }

}

@media screen and (max-width: 768px) {
    .hero {
        flex-direction: column;
        .hero-blur {
            width: 14rem;
        }

        .left_h {

            .the_best_ad {
                margin-top: 0;
                font-size: small;
                align-self: center;
                transform: scale(0.8);
            }
            .hero_text {
                font-size: xx-large;
                align-items: center;
                justify-content: center;

                .hero_text_span {
                    font-size: small;
                    font-weight: 200;
                    letter-spacing: 1px;
                    text-align: center;
                }
            }

            .figures {
                div {
                    span {
                        &:nth-of-type(1) {
                            font-size: large;
                        }
                        &:nth-of-type(2) {
                            font-size: small;
                        }
                    }
                }
            }
        }

        .right_h {
            position: relative;
            background-color: transparent;

            .heart_rate {
                left: 1rem;
                top: 2rem;
            }

            .calories {
                position: relative;
                top: 5rem;
                left: 2rem;

                div {
                    :nth-child(2) {
                        color: white;
                        font-size: 1rem;
                    }
                }
            }

            .hero_image {
                position: relative;
                width: 15rem;
                left: 7rem;
                top: 4rem;
                align-self: center;
            }

            .hero_image_back {
                width: 15rem;
                left: 2rem;
                top: 0rem;
            }
        }
    }
}