@import '../../scss/vars';

.programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;

    .programs_header {
        display: flex;
        gap: 5rem;
        font-weight: bold;
        font-size: 3rem;
        justify-content: center;
        color: white;
        text-transform: uppercase;
        font-style: italic;
    }

    .program_categories {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
    }

    .category {
        display: flex;
        flex-direction: column;
        background-color: $gray;
        padding: 2rem;
        gap: 1rem;
        color: white;
        justify-content: space-between;

        :nth-child(1) {
            width: 2rem;
            height: 2rem;
            fill: white;
        }

        :nth-child(2) {
            font-size: 1rem;
            font-weight: bold;

        }
        
        :nth-child(3) {
            font-size: 0.9rem;
            line-height: 25px;
        }

        .join_now {
            display: flex;
            align-items: center;
            gap: 2rem;

            img {
                width: 1rem;
            }
        }
        &:hover {
            background: $planCard;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 768px) {
    .programs {

        .programs_header {
            flex-direction: column;
            gap: 1px;
            font-size: x-large;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
        }

        .program_categories {
            grid-template-columns: 1fr;
        }
    }
}