@import '../../scss/vars';

.reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;

    .left_reasons {
        flex: 1 1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        grid-auto-rows: 1fr;

        img {
            object-fit: cover;
        }

        :nth-child(1) {
            width: 12rem;
            grid-row: 1/3;
            height: 28rem;
        }

        :nth-child(2) {
            width: auto;
            height: 16rem;
            grid-column: 2/4;
        }

        :nth-child(3) {
            width: 14rem;
            grid-column: 2/3;
            grid-row: 2;
        }

        :nth-child(4) {
            grid-row: 2;
            grid-column: 3/4;
            width: 10rem;
            height: 11.2rem;
        }
    }

    .right_reasons {
        flex: 1 1;
        text-transform: uppercase;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        span {
            font-weight: bold;
            color: $orange;

        }

        div {
            color: white;
            font-size: 3rem;
            font-weight: bold;
        }

        .details_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: white;
            font-size: 1rem;
            height: 100%;
            gap: 1rem;

            div {
                display: flex;
                align-items: center;
                gap: 1rem;
            }

            img {
                width: 2rem;
                height: 2rem;
            }

            span {
                font-size: 1rem;
                color: white;
            }
        }

        .ourpartners {
            color: $gray;
            font-weight: normal;
        }

        .partners {
            display: flex;
            gap: 1rem;
            img {
                width: 2.5rem;
            } 
        }
    }
}

@media screen and (max-width: 768px) {
    .reasons {
       flex-direction: column;
       
       .left_reasons {
        grid-auto-rows: auto;
        overflow: hidden;

        :nth-child(1) {
            width: 7rem;
            height: 17rem;
        }

        :nth-child(2) {
            width: 15rem;
            height: 10rem;
        }

        :nth-child(3) {
            width: 7rem;
        }

        :nth-child(4) {
            width: 7rem;
            height: 6rem;
        }
       }
    }
}
